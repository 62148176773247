@import '../../01_ASSETS/01_global-values/global-values.scss';

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 10px 30px !important;
  background-color: rgba(0, 0, 0, .25);
  backdrop-filter: blur(2px);
  height: 60px;
  @include text-shadow;

  // HEADER - LAPPONIA SILENT //
  .navbar-brand {
    font-weight: bold;
    font-family: $main-font;
    font-size: 18px;

    &:hover {
      color: $main-accent !important;
    }
  }
    // ALL LINKS //
    .nav {
      @include text-light;
      font-size: 14px;

       // GENERAL LINK //
       .nav-link {
        text-align: center !important;
        width: 100px;
      }
  
      // DROPDOWN LINKS - ACTIVITES //
      .dropdown {
        width: 100px;
        text-align: center;
        
        
        .dropdown-item {
          font-size: 14px;
          color: ghostwhite !important;
          opacity: .75;
          transition: all ease .5s;
          height: 30px;
       
          .dropdown-divider {
            padding: 0;
            margin: 0;
          }
        }
      }
    }

  // OFFCANVAS TOGGLER //
  .navbar-toggler {
    border: none;
    padding: 0;
  }
}

// ________________________________ //

 // OFFCANVAS
#offcanvasNavbar {
  width: 200px;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 0;
  color: white;

  .dropdown, .dropdown-item, .nav-link{
      color: rgb(205, 195, 195);

    &:hover {
      color: $main-accent !important;
    } 
  }

  .dropdown-active > a.dropdown-toggle {
    color: $main-accent;
    font-weight: bold;
  }

  .nav-link.active {
    color: $main-accent;
    font-weight: 400;
    background-color: transparent !important;
  }

  .last-link {
    margin-bottom: 30px;
  }
  
  .dropdown {
    .dropdown-menu {
      background-color: transparent;
      min-width: 100%;
      padding: 0;
      border-radius: 0px;
      border: none;   
      backdrop-filter: blur(5px) !important;
      
      .dropdown-item{
        padding: 0px 10px;
        font-size: 14px;
        transition: all ease .5s;
        height: 30px;
        color: rgb(205, 195, 195); 
        background-color: transparent !important;
      }

      .dropdown-item-active {
        color: $main-accent !important;
        font-weight: bold;
      }
    }
  }
}



   // LANGUAGE SWITCHER //
  .navbar-language {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid ghostwhite;
    border-bottom: 1px solid ghostwhite;
    padding: 10px 0px 12px 0px;
    width: 100%;
    font-size: 10px;

    .languages {
      margin: 0;
      padding: 0px 10px;
      width: 80%;
      color: ghostwhite;

      .selected-language {
        margin: 0;
        padding: 0;
      }

      .language {
        font-weight: bold;
        margin: -5px 0px 0px 0px;
        padding: 0;
      }
    }
  }

// DESKTOP STYLES //
@media (min-width: 768px) {
  .navbar {
    padding: 5px 30px !important;

    #offcanvasNavbar { // WHY IS THIS NEEDED FOR THE DESKTOP STYLES? //
      .last-link {
        margin-bottom: 0px;
      }

      .dropdown { 
        .dropdown-menu {
          backdrop-filter: blur(5px);
          background-color: rgba(0, 0, 0, .25);
          width: fit-content;
          position: absolute;
          left: 5px;
          top: 48px;
          border-radius: 0px;
          border: none;
  
          .dropdown-item {
            padding: 5px 10px;
            font-size: 14px;
            height: 35px; 
            
            &:hover {
              color: $main-accent;
            }  
          }
        }
      }

      .navbar-language { 
        border: none;
        padding: 0px;
        width: 100px;

        .languages {
          display: none;
        }
      }
    }
  }

}
