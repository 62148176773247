/* Starting state */
.fade-enter, .fade-appear {
  opacity: 0;
}

/* Enter state (from the starting state) */
.fade-enter-active, .fade-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

/* Exit state */
.fade-exit {
  opacity: 1;
}

/* Exit state (from the exit state) */
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}
