.language-switcher-container {
  position: relative;
  display: inline-block;

  .language-toggler {
    background-color: transparent;
    border: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .current-flag {
    width: 30px;
    height: 20px;
    border: .75px solid black;
    transition: all .3s ease;
    
    &:hover{
      transform: scale(1.1);
    }

    &:active, &:focus {
      filter: grayscale(50%);
    }
  }

  .switcher-dropdown {
    position: absolute;
    top: 37.5px;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    padding: 5px;

    button {
      background: none;
      border: none;
      cursor: pointer;
      padding-bottom: 5px;

      &:last-child{
        padding-bottom: 5px;
      }

      &:hover, &:focus {
        outline: none;
      }
    }

    .switcher-flag {
      width: 30px;
      height: 20px;
      filter: grayscale(50%);
      transition: all .3s ease;
      border: .5px solid black;

      &:first-child{
        margin-top: 8px;
      }

      &:hover {
        filter: none;
        transform: scale(1.1);
      }
    }
  }

  // DESKTOP STYLES //

  @media (min-width: 768px) {
    .switcher-dropdown {
      position: absolute;
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgba(0,0,0,.25);
      padding: 5px;
    }
  } 
}