// FONTS //
@font-face {
  font-family: 'Rock Salt';
  src: url('../03_fonts/RockSalt-Regular.ttf') format('truetype');
}

$main-font: 'Rock Salt';


// COLORS //
$main: #111111;
$main-accent: rgb(189, 250, 249);
$light: white;


// STYLES //

// Text-styles
@mixin header-light {
  font-family: $main-font;
  text-align: center;
  color: $light;
  @include text-shadow;
}

@mixin header-dark {
  font-family: $main-font;
  text-align: center;
  color: $main;
}

@mixin text-light {
  font-family: Arial, Helvetica, sans-serif;
  text-align: justify;
  color: $light;
  @include text-shadow;
}

@mixin text-dark {
  font-family: Arial, Helvetica, sans-serif;
  text-align: justify;
  color: $main;
}


//Arrows
@mixin down-arrow-light {
  @include header-light;
  transform: rotate(90deg);
}

@mixin up-arrow-light {
  @include header-light;
  transform: rotate(-90deg);
}

@mixin up-arrow-dark {
  @include header-dark;
  transform: rotate(-90deg);
}

//Text-shadow
@mixin text-shadow {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, .75);
}