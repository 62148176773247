body {
  width: 100vw;
  height: 100vh;
}


.lapponia-silent.blur {
  filter: blur(10px);
  transition: filter .5s ease;
}

body, .App {
  background-color: black;
}